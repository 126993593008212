<template>
  <ElDialog
    id="become-self-tour-dialog"
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    class="property-dialog"
    width="545px"
    :fullscreen="$viewport.lt.md"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        <span>
          Convert to self-showing
        </span>
      </h2>
    </div>
    <div>
      <ul class="mt-3">
        <li class="mt-2">
          <p class="d-inline-block">
            Convert the tour back to a self-showing tour
          </p>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <el-button
        :class="$viewport.lt.md ? 'w-100 mb-2' :''"
        size="medium"
        @click.stop
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        :class="$viewport.lt.md ? 'w-100 ml-0' :''"
        type="primary"
        size="medium"
        :loading="loadingRef"
        @click.stop
        @click="becomeSelfTour"
      >
        Convert back
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import { ref } from '@vue/composition-api';

import { showErrorNotify, showSuccessNotify } from '../../utils/NotifyUtil';

export default {
  name: 'BecomeSelfShowingTourDialog',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    scheduleId: {
      type: Number,
      required: true,
    },
    isTakenOver: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const loadingRef = ref(false);
    return {
      loadingRef,
      becomeSelfTour,
    };

    async function becomeSelfTour() {
      loadingRef.value = true;
      try {
        await context.root.$store.dispatch('Schedule/becomeSelfTour', {
          id: props.scheduleId,
        });
        context.emit('close', 'success');
        showSuccessNotify(context, 'Tour converted successfully');
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        loadingRef.value = false;
      }
    }
  },
};
</script>

<style lang="scss">
#become-self-tour-dialog {
  .el-dialog--center .el-dialog__body {
    padding: 1rem 0.5rem;
  }

  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: theme-color(primary);
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    line-height: 1.5rem;
    font-size: 1.5rem;
    margin-left: -1.5rem;
    vertical-align: top;
  }
}
</style>
