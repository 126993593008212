<template>
  <div class="row gutter-1">
    <div class="activity-date">
      {{ activity.date }}
    </div>
    <div class="col-auto">
      <div
        v-if="activity.type.includes(activityTypes.TOUR_COMPLETED) || activity.type === activityTypes.TOUR_TIME_OUT"
        :style="[activityTypes.TOUR_COMPLETED_SELF_SHOWING, activityTypes.TOUR_TIME_OUT].includes(activity.type) ? 'width: 67px' : ''"
      >
        <div
          v-if="[activityTypes.TOUR_COMPLETED_SELF_SHOWING, activityTypes.TOUR_TIME_OUT].includes(activity.type)"
          class="activity-icon border-primary float-left"
        >
          <i class="sdicon-key text-primary" />
        </div>
        <div
          :class="{'position-absolute positioning': [activityTypes.TOUR_COMPLETED_SELF_SHOWING, activityTypes.TOUR_TIME_OUT].includes(activity.type)}"
        >
          <div
            class="activity-icon border-0 bg-primary"
          >
            <i class="sdicon-check text-white" />
          </div>
        </div>
      </div>
      <div
        v-else
        class="activity-icon"
        :class="{'border-primary': activity.type===activityTypes.TOUR_SCHEDULED_SELF_SHOWING}"
      >
        <i
          :class="[activityIconClass, colorClass]"
        />
      </div>
    </div>
    <div :class="$viewport.lt.md ? 'col-12 pt-2': 'col'">
      <h3>
        <span v-if="activity.type===activityTypes.TOUR_COMPLETED_SELF_SHOWING">
          Self-Showing Tour Completed
        </span>
        <span v-if="activity.type===activityTypes.TOUR_TIME_OUT">
          Self-Showing Tour Completed - Missing Report
        </span>
        <span v-if="activity.type===activityTypes.TOUR_BUDGET_REACHED">
          Tour Budget Reached
        </span>
        <span v-if="activity.type===activityTypes.TOUR_COMPLETED">
          {{ tourType }} with
          <span
            v-for="(lead, index) in activity.leads"
            :key="lead.id"
          >
            <ProspectProfileLink :prospect="lead" />
            <span v-if="index !== (activity.leads.length - 1)">, </span>
          </span>
          was completed
        </span>
        <span v-if="activity.type === activityTypes.TOUR_NO_SHOW">{{ tourType }} no show</span>
        <span v-else-if="activity.type === activityTypes.TOUR_TECHNICAL_ISSUE">{{ tourType }} technical issue</span>
        <span v-else-if="activity.type === activityTypes.TOUR_CANCELLED">{{ tourType }} cancelled</span>
        <span v-else-if="activity.type === activityTypes.TOUR_NO_AGENT_FOUND">No agent found</span>
        <span v-else-if="activity.type=== activityTypes.TOUR_SCHEDULED_SELF_SHOWING">Self-showing Tour Scheduled</span>
        <span v-else-if="activity.type === activityTypes.TOUR_SCHEDULED">{{ tourType }} scheduled
          <LiveVideoTourTag
            v-if="activity.is_video"
            :small="true"
          />
        </span>
        <span v-else-if="activity.type===activityTypes.TOUR_TAKEN_OVER || activity.type===activityTypes.TOUR_SURRENDERED">{{ tourType }} reassigned</span>
        <router-link
          v-if="activity.schedule_id && !activity.type.includes(activityTypes.TOUR_COMPLETED)"
          :to="{name:'app.tours.show', params:{id:activity.schedule_id}}"
          class="activity-id"
        >
          # {{ activity.schedule_id }}
        </router-link>
      </h3>
      <div class="activity-summary">
        <span v-if="activity.type===activityTypes.TOUR_BUDGET_REACHED">
          Budget of {{ activity.max_budgeted_tours }} {{ $pluralize('tour', activity.max_budgeted_tours) }} reached - Showings have been paused.
        </span>
        <span v-else-if="cancelledBy">{{ cancelledBy }}</span>
        <ul
          v-else-if="activity.type !== activityTypes.TOUR_SCHEDULED"
          :class="activity.leads.length === 1 ? 'non-concurrent' : ''"
        >
          <li
            v-for="lead in activity.leads"
            :key="lead.schedule_inquiry_id"
          >
            <span v-if="surveyResult[lead.schedule_inquiry_id] === 'Yes'">
              Our agent thinks
              <ProspectProfileLink :prospect="lead" /> will apply
            </span>
            <span v-else-if="surveyResult[lead.schedule_inquiry_id] === 'No'">
              Our agent thinks
              <ProspectProfileLink :prospect="lead" /> will not apply
            </span>
            <span v-else-if="surveyResult[lead.schedule_inquiry_id] === 'No show'">
              <ProspectProfileLink :prospect="lead" /> did not show up for the {{ tourType }}
            </span>
          </li>
        </ul>
      </div>
      <div
        v-if="(activity.agent_full_name || activity.taken_over_by) &&
          ![
            activityTypes.TOUR_SCHEDULED,
            activityTypes.TOUR_SCHEDULED_SELF_SHOWING,
            activityTypes.TOUR_SURRENDERED,
            activityTypes.TOUR_COMPLETED_SELF_SHOWING
          ].includes(activity.type)"
        class="activity-data"
      >
        <span class="label">
          Assignee
        </span>
        <span class="value">
          {{ activity.agent_full_name ? activity.agent_full_name : activity.taken_over_by }}
        </span>
      </div>
      <div
        v-else-if="activity.type === activityTypes.TOUR_SURRENDERED"
        class="activity-data"
      >
        <span class="label">
          Assignee
        </span>
        <span class="value">
          Showdigs
        </span>
      </div>
      <div
        v-if="activity.schedule_id && activity.type.includes(activityTypes.TOUR_COMPLETED)"
        class="activity-data "
      >
        <span class="label">
          {{ tourType }} ID
        </span>
        <span class="value">
          #{{ activity.schedule_id }}
        </span>
      </div>
      <div
        v-if="leads.length && leads.general.length && !activity.type.includes(activityTypes.TOUR_COMPLETED)"
        class="activity-data"
      >
        <span class="label">
          {{ $pluralize('Prospect', leads.general.length) }}
        </span>
        <span class="value sd-flex-1">
          <ProspectProfileLink
            v-for="(lead, index) in leads.general"
            :key="lead.id"
            :prospect="lead"
          >
            <template
              v-if="index < leads.general.length -1"
              #suffix
            >
              <span class="text-black">, </span>
            </template>
          </ProspectProfileLink>
        </span>
      </div>
      <div
        v-if="leads.length && leads.canceled.length && !activity.type.includes(activityTypes.TOUR_COMPLETED)"
        class="activity-data"
      >
        <span class="label label-width-5">
          No Shows
        </span>
        <span class="value sd-flex-1">
          <ProspectProfileLink
            v-for="(lead, index) in leads.canceled"
            :key="lead.id"
            :prospect="lead"
          >
            <template
              v-if="index < leads.canceled.length -1"
              #suffix
            >
              <span class="text-black">, </span>
            </template>
          </ProspectProfileLink>
        </span>
      </div>
      <div
        v-if="activity.tour_at &&
          ![
            activityTypes.TOUR_TAKEN_OVER,
            activityTypes.TOUR_SURRENDERED,
          ].includes(activity.type)"
        class="activity-data"
      >
        <span class="label">
          Date
        </span>
        <span class="value">
          {{ activity.tour_at }}
        </span>
      </div>
      <template v-if="activity.type.includes(activityTypes.TOUR_COMPLETED) && !isTourActivity">
        <router-link :to="{name: 'app.tours.show', params : { id : activity.schedule_id}}">
          <img
            :src="Arrow"
            class="forward-arrow-in-circle"
          >View {{ tourType }} Report
        </router-link>
      </template>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api';
import Arrow from '@/assets/forward-arrow-in-circle.svg';
import { getBadgeFormatStatus } from '@/utils/ScheduleUtil';
import { pluck } from '@/utils/PluckUtil';

import ProspectProfileLink from '@/components/prospect/ProspectProfileLink';
import { groupLeadsByStatus } from '@/utils/LeadsUtil';
import Inquiry from '../../../constants/Inquiry';
import LiveVideoTourTag from '../../tour/LiveVideoTourTag';
import SurveyResult from '../../../constants/SurveyResult';
import { activityTypes } from '../../../constants/Commons';

export default {
  components: { LiveVideoTourTag, ProspectProfileLink },
  props: {
    activity: {
      required: true,
      type: Object,
    },
    isTourActivity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cancelledBy = computed(computeCancellation);
    const surveyResult = computed(computeSurveyResult);
    const leadNames = computed(computeLeadNames);
    const tourType = computed(computeTourType);
    const activityIconClass = computed(computeIconClass);
    const colorClass = computed(computeColorClass);
    const leads = props.activity.leads ? groupLeadsByStatus(props.activity) : {};
    return {
      cancelledBy,
      surveyResult,
      leadNames,
      Arrow,
      Inquiry,
      tourType,
      activityIconClass,
      colorClass,
      activityTypes,
      leads,
    };
    function computeColorClass() {
      const redActivityTypes = [
        activityTypes.TOUR_CANCELLED,
        activityTypes.TOUR_NO_SHOW,
        activityTypes.TOUR_BUDGET_REACHED,
        activityTypes.TOUR_TECHNICAL_ISSUE,
      ];
      if (redActivityTypes.includes(props.activity.type)) {
        return 'text-red';
      }
      // eslint-disable-next-line max-len
      if ([activityTypes.TOUR_COMPLETED_SELF_SHOWING, activityTypes.TOUR_SCHEDULED_SELF_SHOWING, activityTypes.TOUR_TIME_OUT].includes(props.activity.type)) {
        return 'text-primary';
      }
      if ([activityTypes.TOUR_TAKEN_OVER, activityTypes.TOUR_SURRENDERED].includes(props.activity.type)) {
        return 'text-purple';
      }
      if ([activityTypes.TOUR_NO_AGENT_FOUND].includes(props.activity.type)) {
        return 'text-yellow';
      }
      return '';
    }

    function computeIconClass() {
      const closeActivityTypes = [
        activityTypes.TOUR_CANCELLED,
        activityTypes.TOUR_BUDGET_REACHED,
        activityTypes.TOUR_NO_AGENT_FOUND,
        activityTypes.TOUR_SURRENDERED,
      ];
      if (closeActivityTypes.includes(props.activity.type)) {
        return 'sdicon-close';
      }
      // eslint-disable-next-line max-len
      if ([activityTypes.TOUR_COMPLETED_SELF_SHOWING, activityTypes.TOUR_SCHEDULED_SELF_SHOWING, activityTypes.TOUR_TIME_OUT].includes(props.activity.type)) {
        return 'sdicon-key';
      }
      if ([activityTypes.TOUR_NO_SHOW, activityTypes.TOUR_TAKEN_OVER].includes(props.activity.type)) {
        return props.activity.type === activityTypes.TOUR_NO_SHOW ? 'sdicon-eye-slash' : 'sdicon-eye';
      }
      if ([activityTypes.TOUR_TECHNICAL_ISSUE].includes(props.activity.type)) {
        return 'sdicon-exclamation-triangle';
      }
      return 'sdicon-calendar-plus';
    }

    function computeTourType() {
      if (props.activity.is_open_house) {
        return 'Open House';
      }
      if (props.activity.is_video) {
        return 'Live video tour';
      }
      return 'Tour';
    }

    function computeCancellation() {
      const status = props.activity.status;
      if (!status.includes('cancelled') || props.activity.type !== activityTypes.TOUR_CANCELLED) {
        return undefined;
      }
      return getBadgeFormatStatus(status).label;
    }

    function computeSurveyResult() {
      const surveyResult = {};
      if (!props.activity.survey_results) {
        return surveyResult;
      }
      const surveyResults = props.activity.survey_results;
      props.activity.leads.forEach((lead) => {
        const result = surveyResults.find((result) => result.key && result.key === SurveyResult.apply.key
          && result.schedule_inquiry_id === lead.schedule_inquiry_id);
        // if no result it means it's a no show, in that case take the first answer which is the tour outcome
        surveyResult[lead.schedule_inquiry_id] = result ? result.answer : surveyResults[0].answer;
      });
      return surveyResult;
    }

    function computeLeadNames() {
      return pluck(props.activity.leads, 'full_name');
    }
  },
};
</script>
<style lang="scss" scoped>
.sd-flex-1 {
  flex: 1;
}
.forward-arrow-in-circle {
  position: relative;
  top: 2px;
  margin-right: 0.5rem;
}

.activity-summary ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

        > li:not(:first-child) {
            margin-top: 0.25rem;
        }
    }
    .positioning {
      left: 37px
    }
</style>
