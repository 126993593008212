<template>
  <ElDialog
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    :fullscreen="$viewport.lt.md"
    width="545px"
    :title="computedTitle()"
    @close="$emit('close')"
  >
    <p>
      Are you sure you want to cancel this {{ schedule.is_open_house ? 'open house' : 'tour' }}?
    </p>
    <el-radio-group
      id="cancel-tour-radio-group"
      v-model="cancelWithSurrenderRef"
      class="row"
    >
      <el-radio
        class="col-10 mt-2 ml-4"
        :label="false"
      >
        Cancel it. We'll notify the prospect and they will be able to schedule it for another time.
      </el-radio>
      <el-radio
        class="col-10 mt-2 ml-4"
        :disabled="surrenderOptionDisabled"
        :label="true"
      >
        Assign it back to Showdigs. A Showdigs agent will take care of this {{ schedule.is_open_house ? 'open house' : 'tour' }} on your behalf.
        <small
          v-if="surrenderOptionDisabled"
          class="d-flex text-black font-italic"
        >
          <template v-if=" !schedule.property_market_showing_agents_available">
            Only available for properties within the supported areas.
          </template>
          <template v-else>
            Only available up to
            {{ getSettingValueByKey('min_time_to_schedule_tour', schedule.property_market_id) }}
            minutes before the {{ schedule.is_open_house ? 'open house' : 'tour' }}
          </template>
        </small>
      </el-radio>
    </el-radio-group>

    <div
      slot="footer"
      class="dialog-footer mt-3 d-flex justify-content-center"
    >
      <ElButton
        size="medium"
        @click="$emit('close')"
      >
        Keep {{ schedule.is_open_house ? 'open house' : 'tour' }}
      </ElButton>
      <ElButton
        v-if="cancelWithSurrenderRef === false"
        size="medium"
        type="danger"
        :loading="dialogState.isLoading"
        @click="cancelTour"
      >
        Cancel {{ schedule.is_open_house ? 'open house' : 'tour' }}
      </ElButton>
      <ElButton
        v-else
        size="medium"
        type="primary"
        :loading="dialogState.isLoading"
        @click="cancelTour"
      >
        Assign to Showdigs
      </ElButton>
    </div>
  </ElDialog>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';

import { getSettingValueByKey } from '@/utils/SettingUtil';
import { canBeSurrendered } from '../../utils/ScheduleUtil';
import { showErrorNotify, showSuccessNotify } from '../../utils/NotifyUtil';

export default {
  name: 'TourCancelDialog',
  props: {
    schedule: {
      type: Object,
      required: true,
      default: () => {},
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const dialogState = reactive(defaultDialogState);
    const cancelWithSurrenderRef = ref(false);
    const surrenderOptionDisabled = computed(() => !canBeSurrendered(props.schedule));

    return {
      cancelTour,
      surrenderOptionDisabled,
      cancelWithSurrenderRef,
      dialogState,
      getSettingValueByKey,
      computedTitle,
    };

    function computedTitle() {
      if (props.schedule.is_open_house) {
        return 'Cancel Open House';
      }
      return 'Cancel Tour';
    }

    async function cancelTour() {
      dialogState.isLoading = true;

      if (cancelWithSurrenderRef.value === true) {
        try {
          await context.root.$store.dispatch('Schedule/surrender', { id: props.schedule.id });
          const message = `${props.schedule.is_open_house ? 'Open House' : 'Tour'} was successfully assigned to Showdigs`;
          showSuccessNotify(context, message);
          context.emit('close', 'success');
        } catch (error) {
          showErrorNotify(context, error.message);
        } finally {
          dialogState.isLoading = false;
        }
      } else {
        try {
          await context.root.$store.dispatch('Tour/cancel', props.schedule.tour_id);
          dialogState.isLoading = false;
          const message = `${props.schedule.is_open_house ? 'Open House' : 'Tour'} cancelled successfully`;
          showSuccessNotify(context, message);
          context.emit('close', 'success');
        } catch (error) {
          showErrorNotify(context, error.message);
        } finally {
          dialogState.isLoading = false;
        }
      }
    }
  },
};

const defaultDialogState = {
  isLoading: false,
};
</script>
<style lang="scss">
    #cancel-tour-radio-group{
        .el-radio {
            white-space: normal;
        }
        .el-radio__input{
            line-height: 2;
        }
        .el-radio__label{
            line-height: 1.5;
            position: absolute;
        }
        .el-radio__input.is-checked + .el-radio__label{
            color: inherit;
        }
    }
</style>
