<template>
  <SdPage
    id="edit-property-page"
    :style="{
      padding: isRenderedFromUnitPage ? 0 : null,
      'pointer-events': uiFlags.isUnitDeleted ? 'none' : null,
      opacity: uiFlags.isUnitDeleted ? 0.5 : null
    }"
  >
    <TourCancellationsDialog
      ref="tourCancellationsDialog"
      @confirm-submit="submit(true)"
      @cancel-submit="reset"
    />
    <UnitDeleteDialog
      v-if="$viewport.gt.md && unitRef && !unitRef.active_showing_profile"
      :unit="unitRef"
      :visible="uiFlags.isUnitDeleteDialogVisible"
      @close="unitDeleteDialogClosed"
    />

    <SdLoadingLayer :init="init">
      <template v-slot:loaded>
        <ElForm
          ref="editPropertyForm"
          :model="payloadRef"
          class="edit-property-form align-items-center bg-white"
          :class="isRenderedFromUnitPage ? 'p-4' : ''"
          @submit.native.prevent="submit()"
        >
          <div
            class="row edit-property-row"
            :class="isRenderedFromUnitPage ? '' : 'mt-4'"
          >
            <div
              class="col-12"
              :class="{ 'col-md-7': !isRenderedFromUnitPage }"
            >
              <ElCollapse
                :value="activeSectionName"
                accordion
                @change="name => $emit('expand-click', { name })"
              >
                <!-- Property section -->
                <ElCollapseItem
                  v-if="sections.property.isVisible({ property: propertyRef, showingProfile: showingProfileRef, unit: unitRef })"
                  :title="sections.property.label"
                  :name="sections.property.name"
                >
                  <PropertyMainDetails
                    ref="form-main-details"
                    :selectable-tags="selectableTagsRef"
                    :property="propertyRef"
                    :is-invalid-property-address="isInvalidPropertyAddress"
                    :suggested-property-address="suggestedPropertyAddress"
                    :property-with-same-address-ref="propertyWithSameAddressRef"
                    :business-source="businessSourceRef"
                    :init="init"
                    :showing-profile-type="get(showingProfileRef, 'type')"
                    @update-property-payload="updatePropertyPayload"
                    @update-rent="updateShowingProfilePayload"
                    @update-availability-date="(date) => updateShowingProfilePayload(
                      { screening_criteria: { ...payloadRef.showing_profile.screening_criteria, availability_date: date} },
                    )"
                    @update-property-url="(url) => updateShowingProfilePayload({ property_url: url} )"
                    @update-move-in-cost="(moveInCost) => updateShowingProfilePayload(
                      { screening_criteria: { ...payloadRef.showing_profile.screening_criteria, move_in_cost: moveInCost} },
                    )"
                  />
                </ElCollapseItem>

                <!-- General section -->
                <ElCollapseItem
                  v-if="sections.general.isVisible({ property: propertyRef, showingProfile: showingProfileRef, unit: unitRef })"
                  :title="sections.general.label"
                  :name="sections.general.name"
                >
                  <ShowingProfileGeneralInfo
                    :business="businessRef"
                    :property="propertyRef"
                    :unit="unitRef"
                    :showing-profile="showingProfileRef"
                    :is-edit="true"
                    @update-selected-listing-manager="updateSelectedListingManager"
                    @update-property-payload="updatePropertyPayload"
                    @update-showing-profile-payload="updateShowingProfilePayload"
                  />
                  <ShowingProfileOwnerReportSettings
                    v-if="showingProfileRef"
                    :property="propertyRef"
                    :showing-profile="showingProfileRef"
                    :listing-owner-email="selectedListingManagerEmail"
                    @update-owners-emails="updateOwnersEmails"
                    @update-send-owner-reports="updateSendOwnerReports"
                  />
                  <ShowingProfileVacancy
                    v-if="!isPublishListing"
                    :showing-profile="payloadRef.showing_profile"
                    layout="column"
                    with-title
                    class="mt-3"
                    @update-showing-profile-payload="updateShowingProfilePayload"
                  />
                </ElCollapseItem>

                <!-- Prospects section -->
                <ElCollapseItem
                  v-if="sections.prospects.isVisible({ property: propertyRef, showingProfile: showingProfileRef, unit: unitRef })"
                  :title="sections.prospects.label"
                  :name="sections.prospects.name"
                >
                  <ShowingProfileNotesToProspect
                    v-if="showingProfileRef"
                    :general-notes="showingProfileRef.general_notes"
                    :showing-profile="showingProfileRef"
                    :is-edit="true"
                    :business="businessRef"
                    @change="general_notes => updateShowingProfilePayload({ general_notes: general_notes.value })"
                    @update-showing-profile-payload="updateShowingProfilePayload"
                  />
                  <div class="row align-items-center my-2">
                    <div class="col">
                      <span class="font-17">
                        Pre-screening
                      </span>
                    </div>
                    <div
                      v-if="payloadRef.showing_profile.screening_template_id"
                      class="col-auto"
                    >
                      <ScreeningTemplateDropdown
                        v-model="payloadRef.showing_profile.screening_template_id"
                        :screening-templates="screeningTemplatesRef"
                        @change="updateScreeningTemplateDisplay"
                      />
                    </div>
                  </div>
                  <ShowingProfilePreScreening
                    :key="payloadRef.showing_profile.screening_template_id"
                    :screening-template="selectedScreeningTemplateRef"
                    :is-pre-screening-enabled="payloadRef.showing_profile.is_pre_screening_enabled"
                    :prev-version="!payloadRef.showing_profile.screening_template_id"
                  >
                    <template v-slot:property-screening>
                      <ShowingProfilePropertyScreening
                        v-if="screeningCriteriaRef"
                        :screening-criteria="propertyRef.screening_criteria"
                        :unit="unitRef"
                        @change="(screening_criteria) => updatePropertyPayload({ screening_criteria })"
                      />
                    </template>
                    <template v-slot:unit-screening>
                      <ShowingProfileUnitScreening
                        v-if="screeningCriteriaRef"
                        :listing-data="unitRef.listing_identifier ? {} : null"
                        :screening-criteria="showingProfileRef.screening_criteria"
                        :custom-screening="showingProfileRef.custom_screening"
                        @change="(screening_criteria) => updateShowingProfilePayload(
                          { screening_criteria: { ...payloadRef.showing_profile.screening_criteria, ...screening_criteria } },
                        )"
                        @custom-question-update="custom_screening => updateShowingProfilePayload(
                          { custom_screening: custom_screening }
                        )"
                      />
                    </template>
                    <template v-slot:additional-unit-screening>
                      <ShowingProfileAdditionalUnitScreening
                        :additional-unit-screening="showingProfileRef.screening_criteria.additional_criteria"
                        @change="(additional_criteria) => updateShowingProfilePayload(
                          { screening_criteria: { ...payloadRef.showing_profile.screening_criteria, additional_criteria } },
                        )"
                      />
                    </template>
                  </ShowingProfilePreScreening>
                </ElCollapseItem>

                <!-- Schedule section -->
                <ElCollapseItem
                  v-if="showingProfileRef && sections.schedule.isVisible({ property: propertyRef, showingProfile: showingProfileRef, unit: unitRef })"
                  :title="sections.schedule.label"
                  :name="sections.schedule.name"
                >
                  <ShowingSchedule
                    mode="edit"
                    :business="businessRef"
                    :property="payloadRef.property"
                    :showing-profile="payloadRef.showing_profile"
                    @update-showing-profile-payload="updateShowingProfilePayload"
                  />
                </ElCollapseItem>

                <!-- Showings section -->
                <ElCollapseItem
                  v-if="showingProfileRef && sections.showings.isVisible({ property: propertyRef, showingProfile: showingProfileRef, unit: unitRef })"
                  :title="sections.showings.label"
                  :name="sections.showings.name"
                >
                  <template v-if="!isPublishListing">
                    <ShowingProfileToursBudget
                      v-if="!isSelfShowing"
                      :current-budget="showingProfileRef.max_budgeted_tours"
                      :minimum-budget="showingProfileRef.paid_tours_count"
                      @update-budget="updateBudget"
                    >
                      <template
                        v-if="showingProfileRef.paid_tours_count"
                        slot="alert"
                      >
                        <ElAlert
                          :closable="false"
                          class="mb-2"
                          type="info"
                          show-icon
                        >
                          <strong>
                            {{ showingProfileRef.paid_tours_count }} Paid
                            {{ showingProfileRef.paid_tours_count > 1 ? "tours were" : "Tour was" }}
                            completed
                          </strong>
                          since budget was set for this showing
                        </ElAlert>
                      </template>
                    </ShowingProfileToursBudget>
                    <div class="font-17 mt-3 text-gray-darker">
                      Access instructions
                    </div>
                    <div class="mb-3 text-gray-dark">
                      Note that Showdigs does not support MLS lockboxes
                    </div>
                    <ShowingProfileAccessInstructions
                      :business="businessRef"
                      :property="propertyRef"
                      :showing-profile="showingProfileRef"
                      :unit="unitRef"
                      :is-edit-flow="true"
                      @update-showing-profile-payload="updateShowingProfilePayload"
                      @update-property-payload="updatePropertyPayload"
                    />
                  </template>

                  <div class="d-flex align-content-center font-17 justify-content-between mt-5 mb-3">
                    <span><i class="sdicon-fingerprint text-gray-dark mr-2" />AI Identity Verification</span>
                    <ElSwitch
                      v-model="payloadRef.showing_profile.has_identity_verification"
                      :width="44"
                      class="d-block align-self-center"
                    />
                  </div>
                  <div class="text-gray-dark mb-2">
                    Protect yourself from scammers by using AI-powered facial recognition and ID verification to
                    verify prospects before they can schedule a tour.
                  </div>
                </ElCollapseItem>
              </ElCollapse>
            </div>

            <div class="col-12 bottom-form-controls">
              <div class="row no-gutters">
                <ElButton
                  v-if="$viewport.gt.md && unitRef && !unitRef.active_showing_profile && propertyRef.units.length > 1 && !isIntegrationEnabled"
                  class="property-edit-action-button"
                  type="danger"
                  size="medium"
                  plain
                  :loading="formStateRef.loading"
                  :disabled="isActionDisabled"
                  @click.native="uiFlags.isUnitDeleteDialogVisible = true"
                >
                  Delete unit
                </ElButton>
                <ElButton
                  class="property-edit-action-button ml-lg-auto"
                  type="primary"
                  size="medium"
                  :loading="formStateRef.loading"
                  :disabled="isActionDisabled"
                  @click.native="submit()"
                >
                  Save changes
                </ElButton>
              </div>
            </div>

            <div
              v-if="!isRenderedFromUnitPage && showingProfileRef === null && !$store.getters['Auth/accountIsInactive']"
              class="col-12 col-md-6"
            >
              <ElDivider />
              <h2>Ready to start showing this unit?</h2>
              <ShowingProfileSetupButtons
                :property="propertyRef"
                :pre-selected-unit="unitRef"
              />
            </div>
          </div>
        </ElForm>
      </template>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import merge from 'lodash.merge';
import get from 'lodash.get';
import has from 'lodash.has';
import DateTime from '@/constants/DateTime';
import * as Sentry from '@/utils/SentryUtil';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import { getUnitById } from '@/utils/PropertyUtil';
import UnitDeleteDialog from '@/components/unit/UnitDeleteDialog';
import ShowingSchedule from '@/components/showing-profile/ShowingSchedule';
import TourCancellationsDialog from '@/components/tour/TourCancellationsDialog';
import ShowingProfileNotesToProspect from '@/components/showing-profile/ShowingProfileNotesToProspect';
import ShowingProfileAdditionalUnitScreening from '@/components/showing-profile/ShowingProfileAdditionalUnitScreening';
import Showing from '@/constants/Showing';
import { AccessHardware } from '@/constants/AccessHardware';
import ShowingProfilePreScreening from '@/components/showing-profile/ShowingProfilePreScreening';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import moment from 'moment';
import { defaultUnitScreeningPayload, editSectionsDefinitions } from '@/constants/Unit';
import { PropertyType } from '@/constants/Property';
import { formatUnitBathrooms } from '@/utils/UnitUtil';
import ScreeningTemplateDropdown from '@/components/showing-profile/screening-template/ScreeningTemplateDropdown.vue';
import PropertyMainDetails from '@/components/property/PropertyMainDetails';
import ShowingProfileGeneralInfo from '@/components/showing-profile/ShowingProfileGeneralInfo';
import ShowingProfileAccessInstructions from '@/components/showing-profile/ShowingProfileAccessInstructions';
import ShowingProfileVacancy from '@/components/showing-profile/ShowingProfileVacancy';
import ShowingProfilePropertyScreening, {
  defaultPropertyScreeningPayload,
} from '@/components/showing-profile/ShowingProfilePropertyScreening';
import ShowingProfileUnitScreening from '@/components/showing-profile/ShowingProfileUnitScreening';
import ShowingProfileSetupButtons from '@/components/showing-profile/ShowingProfileSetupButtons';
import ShowingProfileOwnerReportSettings from '@/components/showing-profile/ShowingProfileOwnerReportSettings';
import ShowingProfileToursBudget from '@/components/showing-profile/ShowingProfileToursBudget';
import momentUtil from '@/utils/MomentUtil';

export default {
  name: 'PropertyEdit',
  components: {
    ScreeningTemplateDropdown,
    ShowingProfileNotesToProspect,
    ShowingProfileAdditionalUnitScreening,
    ShowingSchedule,
    ShowingProfilePreScreening,
    ShowingProfileToursBudget,
    PropertyMainDetails,
    ShowingProfileGeneralInfo,
    ShowingProfileAccessInstructions,
    ShowingProfileVacancy,
    ShowingProfilePropertyScreening,
    ShowingProfileUnitScreening,
    ShowingProfileSetupButtons,
    ShowingProfileOwnerReportSettings,
    TourCancellationsDialog,
    UnitDeleteDialog,
  },
  props: {
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    activeSectionName: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, context) {
    const store = context.root.$store;

    const defaultPayload = {
      property: {
        listing_manager_id: null,
        owners_emails: null,
        address: null,
        pms_code: '',
        property_type: PropertyType.SingleFamilyHome,
        picture: '',
        common_agent_notes: '',
        listing_manager: {},
        picture_url: '',
        units: [],
        screening_criteria: { ...defaultPropertyScreeningPayload, additional_criteria: null },
      },
      showing_profile: {
        property_url: null,
        application_form_url: '',
        lock_box_code: '',
        comments: '',
        is_vacant: true,
        is_open_house: false,
        send_owner_reports: false,
        terms: false,
        tenants_notified: false,
        tenants: [],
        max_budgeted_tours: null,
        showing_windows: [],
        source: null,
        unit_id: null,
        id: null,
        screening_criteria: { ...defaultUnitScreeningPayload },
        custom_screening: [],
        is_pre_screening_enabled: true,
        general_notes: '',
        is_agent_backup_enabled: false,
        codebox_serial_number: null,
        rent: null,
        screening_template_id: null,
        has_identity_verification: false,
      },
    };

    const propertyRef = ref(null);
    const showingProfileRef = ref(null);
    const unitRef = ref(null);
    const payloadRef = ref(defaultPayload);
    const formStateRef = ref(defaultFormState);
    const selectedListingManagerEmail = ref(null);
    const uiFlags = reactive({
      isUnitDeleteDialogVisible: false,
      isUnitDeleted: null,
    });
    const isLoadingPropertyEdit = ref(false);
    const isInvalidPropertyAddress = ref(false);
    const suggestedPropertyAddress = ref(null);
    const screeningCriteriaRef = ref(null);
    const businessRef = ref(null);
    const screeningTemplatesRef = ref({});
    const selectedScreeningTemplateRef = ref({});
    const selectableTagsRef = ref(null);
    const businessSourceRef = ref({});
    const propertyWithSameAddressRef = ref(null);
    const currentUnitId = Number(context.root.$route.params.unit_id);
    const isSelfShowing = computed(() => payloadRef.value.showing_profile.type === Showing.TYPE_SELF_SHOWING);
    const isPublishListing = computed(() => payloadRef.value.showing_profile.type === Showing.TYPE_PUBLISH_LISTING);
    const isIntegrationEnabled = store.getters['BusinessSource/isIntegrationEnabled'];
    const isAppfolioIntegration = store.getters['BusinessSource/isAppfolioIntegration'];
    const isLegacyIntegrationEnabled = store.getters['BusinessSource/isLegacyIntegrationEnabled'];
    const isAppfolioEnabled = isAppfolioIntegration && isIntegrationEnabled;
    const lastUpdatedAt = ref(null);
    const isActionDisabled = computed(computedIsActionDisabled);
    const businessSource = context.root.$store.getters['BusinessSource/get'];
    const integrationType = businessSource?.propexo_integration_id || businessSource?.client_id ? BusinessSourceTypes[businessSource.source_type.toUpperCase()] : '';

    return {
      uiFlags,
      selectableTagsRef,
      sections: editSectionsDefinitions,
      lastUpdatedAt,
      unitRef,
      selectedListingManagerEmail,
      propertyRef,
      showingProfileRef,
      payloadRef,
      isInvalidPropertyAddress,
      suggestedPropertyAddress,
      formStateRef,
      screeningCriteriaRef,
      propertyWithSameAddressRef,
      isIntegrationEnabled,
      businessRef,
      businessSourceRef,
      isSelfShowing,
      isPublishListing,
      isActionDisabled,
      init,
      submit,
      reset,
      unitDeleteDialogClosed,
      updatePropertyPayload,
      updateShowingProfilePayload,
      updateSendOwnerReports,
      updateOwnersEmails,
      updateSelectedListingManager,
      updateBudget,
      get,
      integrationType,
      screeningTemplatesRef,
      selectedScreeningTemplateRef,
      updateScreeningTemplateDisplay,
    };

    function updateScreeningTemplateDisplay(screeningTemplateId) {
      selectedScreeningTemplateRef.value = screeningTemplatesRef.value.find((element, index) => element.id === screeningTemplateId);
    }

    function computedIsActionDisabled() {
      const addressAlreadyExists = Boolean(propertyWithSameAddressRef?.value?.id);

      return !isIntegrationEnabled && addressAlreadyExists;
    }

    async function init() {
      setTimeout(() => {
        context.emit('stop-fetching');
      }, 2000);
      context.emit('start-fetching');
      isLoadingPropertyEdit.value = true;
      businessRef.value = await store.dispatch('Business/get');
      selectableTagsRef.value = await store.dispatch('Business/getListingTags');
      try {
        businessSourceRef.value = await store.dispatch('BusinessSource/index');
      } catch (e) {
        businessSourceRef.value = {};
      }

      screeningCriteriaRef.value = store.getters['ScreeningCriteria/get'] || await store.dispatch('ScreeningCriteria/load');
      const property = await store.dispatch(
          'Property/find',
          context.root.$route.params.id,
      );

      // deactivated properties screening_criteria set to null instead of object, delete the property so the
      // default screening_criteria object will be merged
      if (property.screening_criteria === null) {
        delete property.screening_criteria;
      }
      defaultPayload.property.units = [];
      // make sure current viewed unit is the first
      property.units = property.units.sort((a, b) => {
        if (b.id === currentUnitId) {
          return 1;
        }
        if (b.active_showing_profile && a.id !== currentUnitId) {
          return 1;
        }
        return -1;
      });

      propertyRef.value = merge(defaultPayload.property, property);

      propertyRef.value.units = propertyRef.value.units.map((unit) => ({
        ...unit,
        bathrooms: formatUnitBathrooms(unit.bathrooms),
        isRootUnit: String(context.root.$route.params.unit_id) === String(unit.id),
      }));

      payloadRef.value.property = { ...payloadRef.value.property, ...property };
      selectedListingManagerEmail.value = get(propertyRef, 'value.listing_manager.email', null);
      unitRef.value = getUnitById(propertyRef.value, currentUnitId);
      lastUpdatedAt.value = moment(propertyRef?.value?.updated_at)
          .local() // This ensures the date is formatted based on your machine's timezone
          .format(DateTime.displayFormatMonthWithDayAndShortTime);
      const lastShowingProfile = unitRef.value.last_showing_profile;
      const activeLeasePeriod = unitRef.value.active_lease_period;
      if (lastShowingProfile && lastShowingProfile.deleted_at === null) {
        if (lastShowingProfile.showing_windows) {
          lastShowingProfile.showing_windows = lastShowingProfile.showing_windows.map((window) => ({
            ...window,
            assignee_id: window.assignee_id || 'showdigs',
          }));
        }
        if (lastShowingProfile.screening_criteria && lastShowingProfile.screening_criteria.availability_date) {
          const availabilityDate = lastShowingProfile.screening_criteria.availability_date;
          const timezone = propertyRef.value.timezone;
          const availabilityDateInUTC = momentUtil(availabilityDate, 'UTC');
          const availabilityDateInPropertyTimezone = unitRef.value.origin_id || unitRef.value.listing_identifier ? availabilityDateInUTC.toServerFormat().replace(/-/g, '/') : availabilityDateInUTC.tz(timezone).toServerFormat().replace(/-/g, '/');
          lastShowingProfile.screening_criteria.availability_date = new Date(availabilityDateInPropertyTimezone);
        }

        screeningTemplatesRef.value = businessRef.value.screening_templates.filter((value) => value.enabled === true);
        selectedScreeningTemplateRef.value = screeningTemplatesRef.value.find((value) => value.id === lastShowingProfile.screening_template_id);

        payloadRef.value.showing_profile = {
          ...payloadRef.value.showing_profile,
          ...lastShowingProfile,
          is_pre_screening_enabled: !lastShowingProfile.pre_screening_disabled_at,
        };

        showingProfileRef.value = lastShowingProfile;
        if (!showingProfileRef.value.showing_windows) {
          showingProfileRef.value.showing_windows = [];
        }
        if (activeLeasePeriod) {
          payloadRef.value.showing_profile.has_identity_verification = activeLeasePeriod.has_identity_verification;
        }
      }

      context.emit('set-showing-profile-available', {
        showingProfile: payloadRef.value.showing_profile,
        property: propertyRef.value,
        unit: unitRef.value,
        isShowingProfileAvailable: lastShowingProfile && lastShowingProfile.deleted_at === null ? lastShowingProfile : false,
      });
      context.emit('stop-fetching');

      isLoadingPropertyEdit.value = false;
    }

    async function updatePropertyPayload(componentFields) {
      const fields = componentFields.value ? componentFields.value : componentFields;
      Object.keys(fields).forEach((key) => {
        if (key in payloadRef.value.property) {
          if (typeof fields[key] === 'object' && fields[key] !== null && !(fields[key] instanceof Blob)) {
            if (Array.isArray(fields[key])) {
              payloadRef.value.property[key] = [...fields[key]];
            } else {
              payloadRef.value.property[key] = { ...fields[key] };
            }
          } else {
            payloadRef.value.property[key] = fields[key];
          }
        }
      });

      const newAddressValue = get(fields, 'address.value');
      if (has(fields, 'address.data')) {
        isInvalidPropertyAddress.value = !get(fields, 'address.data.vicinity');

        const possibleSuggestion = get(fields, 'address.value');

        if (isInvalidPropertyAddress.value) {
          // only suggest if suggestion is different and contains a number(except ending with a number)
          if (isInvalidPropertyAddress.value !== possibleSuggestion && /.*\d.*[^\d]$/.test(possibleSuggestion)) {
            suggestedPropertyAddress.value = possibleSuggestion;
          } else {
            suggestedPropertyAddress.value = null;
          }

          Sentry.captureException(new Error('Trying to edit address without a vicinity'), {
            tags: {
              existing_address: get(payloadRef.value, 'property.short_address'),
              suggested_address: suggestedPropertyAddress.value ? suggestedPropertyAddress.value : 'no suggestion was shown to user',
            },
          });
        }
      } else {
        isInvalidPropertyAddress.value = false;
      }

      if (newAddressValue && !isIntegrationEnabled) {
        const propertiesWithTheSameAddress = await context.root.$store.dispatch('Property/get', {
          existing_address_query: newAddressValue,
        });
        propertyWithSameAddressRef.value = propertiesWithTheSameAddress.properties.data.find((property) => property.id !== propertyRef.value.id);
      } else {
        propertyWithSameAddressRef.value = null;
      }
    }

    function updateShowingProfilePayload(componentFields) {
      const fields = componentFields.value ? componentFields.value : componentFields;
      Object.keys(fields).forEach((key) => {
        if (key in payloadRef.value.showing_profile) {
          payloadRef.value.showing_profile[key] = fields[key];
        }
      });
    }

    function updateBudget(budget) {
      updateShowingProfilePayload({ max_budgeted_tours: budget });
    }

    function updateSendOwnerReports(value) {
      updateShowingProfilePayload({ send_owner_reports: value });
    }

    function updateOwnersEmails(value) {
      updatePropertyPayload({ owners_emails: value });
    }

    function updateSelectedListingManager(listingManagerEmail) {
      selectedListingManagerEmail.value = listingManagerEmail;
    }

    async function unitDeleteDialogClosed(status) {
      uiFlags.isUnitDeleteDialogVisible = false;
      if (status === 'success') {
        uiFlags.isUnitDeleted = true;
        showSuccessNotify(context, 'Unit deleted successfully', { dangerouslyUseHTMLString: true }, 'Property Deleted');
        setTimeout(() => {
          context.root.$router.replace({
            name: 'app.units',
            params: {
              id: propertyRef.value.id,
            },
          });
        }, 2500);
      }
    }

    async function reset() {
      showingProfileRef.value = undefined;
      await init();
    }

    async function submit(confirmed = false) {
      if (!isAppfolioEnabled && !isLegacyIntegrationEnabled && propertyWithSameAddressRef.value) {
        showErrorNotify(context, 'Validation error - property with same address already exists');
        return;
      }

      if (isInvalidPropertyAddress.value) {
        showErrorNotify(context, 'Please check that the address you entered is correct');
        return;
      }

      formStateRef.value.loading = true;
      try {
        await context.refs.editPropertyForm.validate();
        await handlePropertyImageUpload();
        await context.refs?.['form-main-details']?.uploadUnitMediaGalleryImages?.();
        await store.dispatch('Property/update', payloadRef.value.property);
        try {
          const showingProfilePayload = {
            ...payloadRef.value.showing_profile,
            screening_criteria: { ...payloadRef.value.showing_profile.screening_criteria },
          };
          showingProfilePayload.showing_windows = payloadRef.value.showing_profile.showing_windows.map((showingWindow) => {
            if (showingWindow.assignee_id === 'showdigs') {
              return {
                ...showingWindow,
                assignee_id: null,
              };
            }
            return { ...showingWindow };
          });
          const availabilityDateInBrowserTz = get(showingProfilePayload, 'screening_criteria.availability_date');
          if (availabilityDateInBrowserTz) {
            const availabilityDateInPropertyTimezone = momentUtil.changeTz(
                availabilityDateInBrowserTz,
                propertyRef.value.timezone,
            );

            const availabilityDateInUTC = momentUtil.changeTz(availabilityDateInPropertyTimezone, 'UTC').toServerFormat();

            showingProfilePayload.screening_criteria.availability_date = availabilityDateInUTC;
          }
          if (showingProfilePayload.id && showingProfileRef.value !== null) {
            const newShowingWindows = showingProfilePayload.showing_windows;
            const isRestricted = newShowingWindows.length > 0;
            const skipCheckWindows = [Showing.TYPE_RESTRICTED, Showing.TYPE_HIGH_AVAILABILITY, Showing.TYPE_SELF_SHOWING]
                .includes(payloadRef.value.showing_profile.type) && !isRestricted;
            if (!confirmed && !skipCheckWindows) {
              const response = await store.dispatch('ShowingProfile/checkWindows', {
                id: showingProfilePayload.id,
                is_vacant: showingProfilePayload.is_vacant,
                is_open_house: showingProfilePayload.is_open_house,
                showing_windows: newShowingWindows,
              });

              confirmed = response[Showing.IMPACT_TYPE_CANCELLABLE].length === 0 && response[Showing.IMPACT_TYPE_NOT_CANCELLABLE].length === 0;
              if (!confirmed) {
                context.refs.tourCancellationsDialog.open(response, propertyRef.value, showingProfileRef.value.is_open_house);
                formStateRef.value.loading = false;
                return;
              }
            }
            if (confirmed || skipCheckWindows) {
              unitRef.value = getUnitById(propertyRef.value, currentUnitId);
              const lastShowingProfile = unitRef.value.last_showing_profile;
              if (lastShowingProfile?.screening_template_id === null) {
                delete showingProfilePayload.screening_template_id;
              }
              const updatedShowingProfilePayload = await store.dispatch('ShowingProfile/update', showingProfilePayload);
              payloadRef.value.showing_profile.id = updatedShowingProfilePayload.id;
              if (showingProfileRef.value.access_hardware !== AccessHardware.CODEBOX
                  && updatedShowingProfilePayload.access_hardware === AccessHardware.CODEBOX) {
                window.location.reload();
              }
            }
          }
          showSuccessNotify(context, 'Property details updated');
          formStateRef.value.loading = false;
          if (props.isRenderedFromUnitPage) {
            context.emit('reloadUnit');
            lastUpdatedAt.value = moment()
                .local() // This ensures the date is formatted based on your machine's timezone
                .format(DateTime.displayFormatMonthWithDayAndShortTime);
          }
        } catch (error) {
          formStateRef.value.loading = false;
          showErrorNotify(context, error.message);
        }
      } catch (error) {
        formStateRef.value.loading = false;
        const message = getValueByKey(error, 'message');
        showErrorNotify(context, message, {
          dangerouslyUseHTMLString: true,
          duration: 0,
        });
      }
    }

    function getValueByKey(obj, key) {
      const messages = [];

      function extractMessages(obj) {
        if (obj instanceof Error) {
          messages.push(`<li>${obj.message}</li>`);
          return;
        }

        for (const prop in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(prop)) {
            if (typeof obj[prop] === 'object') {
              extractMessages(obj[prop]);
            } else if (prop === 'message') {
              messages.push(`<li>${obj[prop]}</li>`);
            }
          }
        }
      }

      extractMessages(obj);

      return `<ul>${messages.join('')}</ul>`;
    }

    async function handlePropertyImageUpload() {
      const picture = get(payloadRef, 'value.property.picture');
      if (picture && picture.size) {
        const formData = new FormData();
        formData.append('file', payloadRef.value.property.picture);
        const response = await store.dispatch('Property/uploadPicture', formData);
        updatePropertyPayload({ picture_url: response.url, picture: response.name });
      }
    }
  },
};

const defaultFormState = {
  loading: false,
};
</script>
<style lang="scss">
#edit-property-page {
  position: relative;

  .el-collapse {
    border-top: none;
  }
  .el-collapse-item__content{
    line-height: initial;
    padding: 10px 0;
  }
  .el-collapse-item__header {
    font-size: 21px;
    font-weight: normal;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 4rem;

    .bottom-form-controls {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      max-width: calc(100vw - 200px);

      &:before {
        z-index: 10;
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        height: 120px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $white 50%);
        width: 100%;
      }
    }

    .property-edit-action-button {
      z-index: 9999;
      height: 3rem;
      min-width: 150px;
      box-shadow: $app-box-shadow;
      margin: 1rem 0.5rem;
    }
  }
}

.el-notification.right {
  .el-notification__content {
    ul {
      padding-left: 0;
    }
  }
}
</style>
