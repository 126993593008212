<template>
  <div>
    <h3 class="font-weight-light font-21 line-height-28 mt-5">
      Weekly Open House Schedule
    </h3>
    <div>
      Open houses allow you to have one showing window with multiple prospects touring the property together.
    </div>
    <ElAlert
      type="info"
      show-icon
      :closable="false"
      class="mt-3"
    >
      <div class="font-17 font-weight-bold">
        Please note
      </div>
      <ul class="mt-2 mb-0 pl-3">
        <li class="mb-2">
          You can only have one open house per day of week.
        </li>
        <li class="mb-2">
          Open houses can be either a 1-hour or 2-hour window.
        </li>
        <li class="mb-2">
          There are no limits on the number of prospects that register to attend.
        </li>
        <li class="mb-2">
          The open house will be canceled if no prospects register to attend by at least 2 hours in advance.
        </li>
      </ul>
    </ElAlert>
    <ElTooltip
      v-for="(showingWindow, index) in showingWindows"
      :key="index"
      :disabled="!showingWindow.disabled"
      placement="right-start"
      popper-class="popover-panel"
    >
      <template slot="content">
        <i class="sdicon-info-circle" />
        <p>Editing will be available right after the upcoming open house begins.</p>
      </template>
      <div class="showing-window">
        <div>
          <ElFormItem
            :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.week_day`"
            :rules="rules.unique_week_day"
          >
            <SdFloatLabel label="Day">
              <ElSelect
                v-model="showingWindow.week_day"
                :disabled="showingWindow.disabled"
                placeholder="Day"
                @input="onDayChange(showingWindow, index)"
              >
                <ElOption
                  v-for="weekday in weekdaysOptions"
                  :key="weekday.value"
                  :label="weekday.label"
                  :value="weekday.value"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div>
          <ElFormItem
            :ref="`from_hour_${index}`"
            :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.from_hour`"
            :rules="rules.from_hour"
          >
            <SdFloatLabel label="From">
              <ElSelect
                v-model="showingWindow.from_hour"
                :disabled="showingWindow.week_day === null || showingWindow.disabled"
                placeholder="From"
                @input="onFromHourChange(showingWindow, index)"
              >
                <ElOption
                  v-for="hourOption in showingWindowsOptions[index].availableFromHoursOptions"
                  :key="hourOption.value"
                  :label="hourOption.label"
                  :value="hourOption.value"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div>
          <ElFormItem
            :ref="`to_hour_${index}`"
            :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.to_hour`"
            :rules="rules.to_hour"
          >
            <SdFloatLabel label="To">
              <ElSelect
                v-model="showingWindow.to_hour"
                :disabled="showingWindow.from_hour === null || showingWindow.disabled"
                placeholder="To"
                @input="emitFormStateChanged"
              >
                <ElOption
                  v-for="hourOption in showingWindowsOptions[index].availableUntilHoursOptions"
                  :key="hourOption.value"
                  :label="hourOption.label"
                  :value="hourOption.value"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div>
          <ElFormItem
            :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.assignee_id`"
            :rules="rules.assignee_id"
          >
            <SdFloatLabel label="Assignee">
              <ElSelect
                v-model="showingWindow.assignee_id"
                :disabled="showingWindow.disabled"
                placeholder="Assignee"
                @input="emitFormStateChanged"
              >
                <ElOption
                  v-for="assignee in assigneesOptions"
                  :key="assignee.value"
                  :disabled="assignee.disabled"
                  :label="assignee.label"
                  :value="assignee.value"
                >
                  {{ assignee.label }}
                  <span
                    v-if="assignee.value !== 'showdigs'"
                    class="float-right"
                  >
                    <i
                      v-if="assignee.isCalendarConnected"
                      class="ml-2 sdicon-calendar-check-o"
                    />
                  </span>
                </ElOption>
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div
          v-if="showingWindows.length > 1"
          :class="{ 'disabled': showingWindow.disabled }"
          class="delete pointer"
          @click.stop="!showingWindow.disabled ? removeShowingWindow(index) : null"
        >
          <ElTooltip
            content="Delete"
            placement="top"
          >
            <i class="sdicon-trash" />
          </ElTooltip>
        </div>
      </div>
    </ElTooltip>
    <ElButton
      v-if="showingWindows.length < MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS"
      size="small"
      class="mt-4"
      @click="addShowingWindow"
    >
      +Add open house
    </ElButton>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import {
  MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS,
} from '@/constants/UnitShowingProfile';
import momentUtil from '@/utils/MomentUtil';
import { shouldDisableShowingWindow } from '@/utils/ShowingWindowUtil';

export default {
  name: 'ShowingOpenHouseSchedule',
  props: {
    showingProfile: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
      required: true,
    },
    mode: {
      required: true,
      type: String,
      default: 'add',
    },
    property: {
      type: Object,
      required: true,
    },
    weekdaysOptions: {
      type: Array,
      required: true,
    },
    hoursOptions: {
      type: Array,
      required: true,
    },
    assigneesOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const showingWindows = ref([]);
    const showingWindowsOptions = ref([]);
    const isReady = ref(false);
    onMounted(() => {
      onInitAddShowingWindow();
      emitFormStateChanged();
      isReady.value = true;
    });

    return {
      MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS,
      showingWindows,
      showingWindowsOptions,
      addShowingWindow,
      removeShowingWindow,
      onDayChange,
      onFromHourChange,
      emitFormStateChanged,
    };

    function onInitAddShowingWindow() {
      for (const index in props.showingProfile.showing_windows) {
          const showingWindowsLength = showingWindows.value.length;

          const shouldNotDisplayAnyMoreWindows = (
              showingWindowsLength === 1
              && props.showingProfile.showing_windows.length !== MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS
          );

          if (shouldNotDisplayAnyMoreWindows || showingWindowsLength === MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS) {
            break;
          }

          const showingWindow = props.showingProfile.showing_windows[index];

          showingWindowsOptions.value.push({ ...showingWindowSettings });
          showingWindows.value.push(showingWindow);

          onDayChange(showingWindow, index);
          setShowingWindowsToHours(showingWindow, index);
        }
    }

    function setShowingWindowsToHours(showingWindow, index) {
      const defaultHour = showingWindowsOptions.value[index].availableUntilHoursOptions[0];
      const savedHour = showingWindowsOptions.value[index].availableUntilHoursOptions.filter((item) => item.value === showingWindow.to_hour);

      if (savedHour[0] || defaultHour?.value) {
        showingWindows.value[index].to_hour = savedHour[0] ? savedHour[0].value : defaultHour.value;
      }
    }

    function addShowingWindow() {
      showingWindows.value.push({ ...defaultShowingWindow });
      showingWindowsOptions.value.push({ ...showingWindowSettings });
    }

    function removeShowingWindow(index) {
      showingWindows.value.splice(index, 1);
    }

    function onDayChange(showingWindow, index) {
      const weekDay = showingWindows.value[index].week_day;
      const dayHoursTmp = props.hoursOptions[weekDay];
      const dayHours = [...dayHoursTmp];
      // We need to reduce 2 slots from the end of the day,
      // so the user will still have one hour to set an open house
      dayHours.splice(dayHours.length - 2, 2);

      showingWindowsOptions.value[index].availableFromHoursOptions = dayHours;
      setUntilHoursOptions(showingWindow, index);

      if (isReady.value) {
        showingWindows.value[index].from_hour = null;
        showingWindows.value[index].to_hour = null;

        emitFormStateChanged();
      }
    }
    function setUntilHoursOptions(showingWindow, index) {
      const weekDay = showingWindows.value[index].week_day;
      const timeSlotFrom = momentUtil(showingWindow.from_hour, null, 'HH:mm');
      const untilOptions = [];
      const dayHours = props.hoursOptions[weekDay];
      const dayHoursCount = dayHours.length;
      const oneHour = 60;
      const toHours = oneHour * 2;
      const stopAfterAmountOfSlots = 2;

      for (let i = 0; i < dayHoursCount; i++) {
        const option = dayHours[i];
        const timeSlotUntil = momentUtil(option.value, null, 'HH:mm');
        const diff = timeSlotUntil.diff(timeSlotFrom, 'minutes');

        if ([oneHour, toHours].includes(diff)) {
          untilOptions.push(option);

          if (untilOptions.length === stopAfterAmountOfSlots) {
            break;
          }
        }
      }

      showingWindowsOptions.value[index].availableUntilHoursOptions = untilOptions;
    }

    function onFromHourChange(showingWindow, index) {
      setUntilHoursOptions(showingWindow, index);

      if (isReady.value) {
        showingWindows.value[index].to_hour = null;
        emitFormStateChanged();
      }
    }

    function emitFormStateChanged() {
      context.emit('update-showing-profile-payload', {
        showing_windows: showingWindows.value,
      });
    }
  },
};

const defaultShowingWindow = {
  week_day: null,
  from_hour: null,
  to_hour: null,
  assignee_id: null,
  disabled: false,
};
const showingWindowSettings = {
  availableFromHoursOptions: [],
  availableUntilHoursOptions: [],
};
</script>

<style lang="scss" scoped>
.showing-window {
  display: flex;
  align-items: center;

  .disabled {
    color: gray-color('dark');
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  > div:not(:first-of-type) {
    margin-left: 0.75rem;
  }

  > div:not(.delete) {
    flex: 1;
  }
}

.ribbon {
  position: absolute;
  top: -23px;
  right: -23px;
}
</style>
