<template>
  <div>
    <div
      v-if="!showingSlots[slotsKey]"
      class="text-center"
    >
      <ElSpinner color="primary" />
    </div>
    <ElForm
      v-if="showingSlots[slotsKey] && showingSlots[slotsKey].length"
      id="unit-schedule-tour-form"
      ref="addInquiryForm"
      :model="payload"
      class="row mt-2"
    >
      <div
        v-if="shouldShowLiveVideoTourCheckbox()"
        class="col-12 pt-2"
      >
        <ElCheckbox
          v-model="payload.is_video"
          name="is_video"
          class="d-flex align-items-center"
          @change="resetPicker"
        >
          <LiveVideoTourTag
            :disabled="!payload.is_video"
            class="ml-2 p-2"
          />
        </ElCheckbox>
      </div>
      <SdShowingSlotInput
        v-model="payload.tour_at"
        class="col-12 pt-0 mb-3"
        :timezone="timezone"
        :showing-slots="showingSlots[slotsKey]"
        @input="timeSelected"
      />
      <div class="col-12">
        <p
          class="text-gray-dark mb-0"
        >
          We'll notify the prospect of the tour. They will still be able to reschedule or cancel.
        </p>
      </div>
    </ElForm>
  </div>
</template>

<script>
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api';
import store from '@/store';
import { showErrorNotify } from '@/utils/NotifyUtil';
import MomentUtil from '@/utils/MomentUtil';
import momentUtil from '@/utils/MomentUtil';
import SdShowingSlotInput from '../common/SdShowingSlotInput';
import LiveVideoTourTag from '../tour/LiveVideoTourTag';

export default {
  name: 'UnitScheduleTour',
  components: { LiveVideoTourTag, SdShowingSlotInput },
  props: {
    unit: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const business = store.getters['Auth/business'];
    const showingSlots = reactive({ slots: null, video_slots: null });
    const payload = ref({ ...defaultPayload });
    const pickerDatetime = reactive({ dateIndex: null, time: null });
    const slotsKey = computed(() => (payload.value.is_video ? 'video_slots' : 'slots'));
    const timezone = computed(computeTimezone);

    const relatedTimeSlots = computed(() => {
      if (!slotsKey.value) {
        return [];
      }
      const slotsInDate = showingSlots[slotsKey.value][pickerDatetime.dateIndex];
      if (!slotsInDate) {
        return [];
      }
      return slotsInDate.timeSlots.filter((timeSlot) => !timeSlot.disabled);
    });
    watch(() => props.unit.id, () => {
      resetPicker();
      getShowingSlots();
    }, { immediate: true });

    return {
      relatedTimeSlots,
      showingSlots,
      slotsKey,
      payload,
      pickerDatetime,
      getShowingSlots,
      timeSelected,
      resetPicker,
      business,
      timezone,
      shouldShowLiveVideoTourCheckbox,
    };

    function shouldShowLiveVideoTourCheckbox() {
      return this.business.video_tours_enabled_at && !isOpenHouse() && !isSelfShowing();
    }

    function isOpenHouse() {
      return props.unit?.last_showing_profile?.is_open_house;
    }

    function isSelfShowing() {
      return props.unit?.last_showing_profile?.is_self_showing;
    }

    async function getShowingSlots() {
      try {
        const showingSlotsResp = await dispatch('Unit/showingSlots', props.unit.id);
        showingSlots.slots = showingSlotsResp.slots.map(mapShowingSlot);
        showingSlots.video_slots = showingSlotsResp.video_slots.map(mapShowingSlot);
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }
    function mapShowingSlot(slot) {
      const propertyTimezone = props.unit.timezone || props.unit.property.timezone;
      return {
        date: slot.date,
        isAvailable: slot.is_available,
        label: context.root.$momentUtil(slot.date, null).toDateFormat(),
        timeSlots: slot.time_slots.map((timeSlot) => ({
          date: timeSlot.tour_at,
          label: context.root.$momentUtil(timeSlot.tour_at, propertyTimezone).toDisplayShortTimeFormat(),
          disabled: timeSlot.is_violating_restrictions,
        })),
      };
    }

    function timeSelected(val) {
      context.emit('time-selected', { is_video: payload.value.is_video, time: val });
    }

    function resetPicker() {
      pickerDatetime.dateIndex = null;
      pickerDatetime.time = null;
    }

    function computeTimezone() {
      if (props.unit) {
        return props.unit.timezone || props.unit.property.timezone;
      }
      return momentUtil.tz.guess();
    }
  },
};

const defaultPayload = {
  tour_at: '',
  is_video: false,
};
</script>
<style lang="scss">
    #unit-schedule-tour-form {
        .el-date-editor.el-input, .el-date-editor.el-input__inner {
            width: 100% !important;
        }

        .el-input__inner {
            padding-right: 1rem;
            padding-left: 1rem;
            height: $--input-height !important; // forcing height, so element-ui doesn't change :disabled attr changed
        }

        .el-input__prefix {
            display: none;
        }
    }
</style>
